import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Content } from "../grid"
import BlogCategory from "../accents/BlogCategory"
import Link from "../content/link"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight } from "@fortawesome/free-solid-svg-icons"
import Rows from "../grid/rows"

function Listing({ item, noContent }) {
  const img = getImage(item.featuredImage.node.localFile)

  return (
    <Content
      gap="md"
      paddingLeft="sm"
      className="blog__listing blog__listing--resource"
    >
      <Link
        to={item.resourceSchema.resourceLink}
        className="blog__related border--rounded"
      >
        <GatsbyImage
          className="blog__related__image"
          image={img}
          alt={item.featuredImage.node.altText}
        />
      </Link>
      <Rows gap="xxs">
        <div>
          <Link
            to={`/resources/${item.resourceCategories.nodes[0].slug}/`}
            className={`h4 text--small text-gradient text-gradient__${item.resourceCategories.nodes[0].extraData.color}`}
          >
            {item.resourceCategories.nodes[0].name}
          </Link>
        </div>
        <Link className="h4" to={item.resourceSchema.resourceLink}>
          {item.title}
        </Link>
        {!noContent && (
          <p className="text--small">{item.resourceSchema.resourceContent}</p>
        )}
        <Link className="h4 h4--xs" to={item.resourceSchema.resourceLink}>
          {item.resourceSchema.ctaText}
          <FontAwesomeIcon className="ml-xxs mt-xxs" icon={faCaretRight} />
        </Link>
      </Rows>
    </Content>
  )
}

export default Listing
