import React from "react"

const IndustryCurve = ({ color, inverted }) => {
  return (
    <svg
      className={
        inverted ? "hero-image--industry__wave-2" : "hero-image--industry__wave"
      }
      xmlns="http://www.w3.org/2000/svg"
      width="325"
      height="95"
      viewBox="0 0 325 95"
    >
      <path
        fill={color}
        d="M0 0s41.837 37.881 122.487 61.573 200.111 33.191 200.111 33.191H0Z"
      />
    </svg>
  )
}

export default IndustryCurve
