import React, { useEffect } from "react"

const HeroForm = ({ formId }) => {
  useEffect(() => {
    initializeHubspotForm()
  })

  const initializeHubspotForm = () => {
    if ("hbspt" in window) {
      window.hbspt.forms.create({
        region: "eu1",
        portalId: "143148669",
        formId,
        target: "#formContainer",
      })
    } else {
      setTimeout(initializeHubspotForm, 500)
    }
  }

  return <div id="formContainer" />
}

export default HeroForm
