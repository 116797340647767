import Listing from "@src/components/blog/resource-listing"
import Section from "@src/components/containers/section"
import SectionContent from "@src/components/containers/section-content"
import Heading from "@src/components/content/heading"
import Content from "@src/components/grid/content"
import Rows from "@src/components/grid/rows"
import React from "react"

const IndustryResources = ({ data }) => {
  const { preTitle, titleText, relatedResources } = data

  return (
    <Section>
      <SectionContent>
        <Rows gap="xl">
          <Content>
            <Heading preheading={preTitle} level={2}>
              {titleText}
            </Heading>
          </Content>

          <div className="blog__articles blog__articles--resources">
            {relatedResources.map((item, index) => (
              <Listing key={index} item={item} noContent />
            ))}
          </div>
        </Rows>
      </SectionContent>
    </Section>
  )
}

export default IndustryResources
