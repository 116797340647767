import Section from "@src/components/containers/section"
import SectionContent from "@src/components/containers/section-content"
import Heading from "@src/components/content/heading"
import Columns from "@src/components/grid/columns"
import Content from "@src/components/grid/content"
import Rows from "@src/components/grid/rows"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import CtaGroup from "@src/components/containers/cta-group"
import Button from "@src/components/interactive/button"

const PricingCTA = ({ titleText, contentText, sectionColor, buttonLink }) => {
  return (
    <Section theme={sectionColor}>
      <SectionContent>
        <div className="card--pricing">
          <Rows>
            <Columns className="card--pricing__container">
              <Content className="px-md lap-px-none lap-pl-xl">
                <Heading className="text--color-white" level={2}>
                  {titleText}
                </Heading>
                <p className="text--color-white">{contentText}</p>
                <CtaGroup>
                  <Button href={buttonLink} className="text--center mt-xs">
                    Get Started
                  </Button>
                </CtaGroup>
              </Content>
              <Content className="card--pricing__cards">
                <StaticImage src="../../images/pricing/casestudy-logo-cards.png" />
              </Content>
            </Columns>
          </Rows>
        </div>
      </SectionContent>
    </Section>
  )
}

PricingCTA.defaultProps = {
  sectionColor: "white",
}

export default PricingCTA
