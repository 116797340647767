import React from "react"
import { graphql } from "gatsby"
import Main from "@src/components/layout/main"
import IndustryHero from "@src/sections/industry/hero"
import StatsBar from "@src/sections/industry/stats-bar"
import IndustryTriColumn from "@src/sections/industry/tri-column"
import IndustryBoxes from "@src/sections/industry/boxes"
import IndustryResources from "@src/sections/industry/resources"
import PricingCTA from "@src/sections/pricing/pricing-cta"

const IndustryPage = ({ data }) => {
  const { hero, statBoxes, triColumn, boxes, resources, cta } =
    data.wpIndustryPage.pageData

  return (
    <Main>
      <IndustryHero data={hero} />
      <StatsBar data={statBoxes} />
      <IndustryTriColumn data={triColumn} />
      <IndustryBoxes data={boxes} />
      <IndustryResources data={resources} />
      <PricingCTA
        titleText={cta.title}
        buttonLink="https://lp.lunio.ai/demo-v1/"
        contentText={cta.content}
        sectionColor="light"
      />
    </Main>
  )
}

export default IndustryPage

export const industryPageQuery = graphql`
  query ($id: String!) {
    wpIndustryPage(id: { eq: $id }) {
      pageData {
        hero {
          imageStyles
          pardotUrl
          subParagraph
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            altText
          }
        }
        statBoxes {
          text
          headlineFigure
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            altText
          }
        }
        triColumn {
          titleText
          preTitleText
          columns {
            text
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              altText
            }
          }
        }
        boxes {
          textBlock
          title
          backgroundStyles
          cta {
            color
            text
            url
          }
          doubleHeight
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            altText
          }
          imageStyles {
            styleKey
            styleValue
          }
        }
        resources {
          preTitle
          titleText
          relatedResources {
            ... on WpResource {
              resourceSchema {
                ctaText
                resourceContent
                resourceLink
              }
              resourceCategories {
                nodes {
                  slug
                  extraData {
                    color
                  }
                  name
                }
              }
              title
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
        cta {
          title
          content
          backgroundColor
          image {
            altText
          }
        }
      }
    }
  }
`
