import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Section from "@src/components/containers/section"
import Columns from "@src/components/grid/columns"
import Rows from "@src/components/grid/rows"
import SectionContent from "@src/components/containers/section-content"
import SectionBackground from "@src/components/containers/section-background"
import Content from "@src/components/grid/content"
import Heading from "@src/components/content/heading"
import Copy from "@src/components/content/copy"
import IndustryCurve from "@src/components/accents/industry-curve"

const IndustryTriColumn = ({ data }) => {
  const { titleText, preTitleText, columns } = data

  return (
    <Section>
      <SectionBackground>
        <IndustryCurve inverted color="#f5f5f5" />
      </SectionBackground>
      <SectionContent>
        <Rows>
          <Columns count="1">
            <Content className="mb-sm">
              <Heading preheading={preTitleText}>{titleText}</Heading>
            </Content>
          </Columns>
          <Columns count="3">
            {columns.map(({ text, title, image }, index) => {
              const img = getImage(image.localFile)

              return (
                <Content key={index}>
                  <div style={{ width: 128 }}>
                    <GatsbyImage alt={image.altText} image={img} />
                  </div>
                  <Heading level={4}>{title}</Heading>
                  <Copy>
                    <p>{text}</p>
                  </Copy>
                </Content>
              )
            })}
          </Columns>
        </Rows>
      </SectionContent>
    </Section>
  )
}

export default IndustryTriColumn
