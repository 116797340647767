import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Section from "@src/components/containers/section"
import Columns from "@src/components/grid/columns"
import Rows from "@src/components/grid/rows"
import SectionContent from "@src/components/containers/section-content"
import Card from "@src/components/containers/card"
import SectionBackground from "@src/components/containers/section-background"
import IndustryCurve from "@src/components/accents/industry-curve"

const StatsBar = ({ data }) => {
  return (
    <Section theme="light">
      <SectionBackground>
        <IndustryCurve color="#ffffff" />
      </SectionBackground>
      <SectionContent>
        <Rows>
          <Columns colgap count="4">
            {data.map(({ text, headlineFigure, image }, index) => {
              const img = getImage(image.localFile)

              return (
                <Card key={index} styleType="solid">
                  <p className="h3">{headlineFigure}</p>
                  <p>{text}</p>
                  <div style={{ maxWidth: 120 }}>
                    <GatsbyImage image={img} alt={image.altText} />
                  </div>
                </Card>
              )
            })}
          </Columns>
        </Rows>
      </SectionContent>
    </Section>
  )
}

export default StatsBar
