import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Section from "@src/components/containers/section"
import SectionBackground from "@src/components/containers/section-background"
import SectionContent from "@src/components/containers/section-content"
import Copy from "@src/components/content/copy"
import Heading from "@src/components/content/heading"
import Columns from "@src/components/grid/columns"
import Content from "@src/components/grid/content"
import Rows from "@src/components/grid/rows"
import Footer from "@src/components/grid/footer"
import ClearbitConverisonForm from "@src/components-smart/forms/clearbit-conversion-form"
import parse from "html-react-parser"
import IndustryCurve from "@src/components/accents/industry-curve"
import HeroForm from "../home/hero-form"

const IndustryHero = ({ data }) => {
  const { pardotUrl, subParagraph, title, image, imageStyles } = data

  const backgroundImage = getImage(image.localFile)

  return (
    <header>
      <Section theme="white">
        <SectionBackground>
          <div className={`hero-image hero-image--industry-${imageStyles}`}>
            <GatsbyImage alt={image.altText} image={backgroundImage} />
          </div>
          <IndustryCurve color="#f5f5f5" />
        </SectionBackground>
        <SectionContent hero>
          <Rows>
            <Columns count="2,1" className="mb-lg">
              <Content gap="lg" paddingRight="none" className="lap-mr-xxl">
                <Heading className="lap-mt-lg superheading" level={1}>
                  {parse(title)}
                </Heading>
                <Copy className="width--industry">
                  <p>{subParagraph}</p>
                </Copy>
                <div className="width--industry">
                  <Footer>
                    <HeroForm formId={pardotUrl} />
                  </Footer>
                </div>
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>
    </header>
  )
}

export default IndustryHero
