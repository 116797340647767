import React, { useEffect, useState } from "react"

const ClearbitConverisonForm = ({ src }) => {
  const [height, setHeight] = useState(160)

  const adjustHeightState = e => {
    if (e.origin !== "https://info.lunio.ai") return
    setHeight(e.data.height)
  }

  useEffect(() => {
    window.addEventListener("message", adjustHeightState, false)

    return () => {
      window.removeEventListener("message", adjustHeightState)
    }
  }, [])

  return (
    <iframe
      src={src}
      width="100%"
      type="text/html"
      height={height}
      frameBorder="0"
    />
  )
}

export default ClearbitConverisonForm
