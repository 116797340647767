import IndustryPageCard from "@src/components/containers/industry-page-card"
import Section from "@src/components/containers/section"
import SectionBackground from "@src/components/containers/section-background"
import SectionContent from "@src/components/containers/section-content"
import React from "react"

const IndustryBoxes = ({ data }) => {
  return (
    <Section theme="light">
      <SectionBackground></SectionBackground>
      <SectionContent>
        <div className="industry-grid">
          {data.map((item, index) => {
            const imageStylesObj = item.imageStyles.reduce(
              (obj, item) =>
                Object.assign(obj, { [item.styleKey]: item.styleValue }),
              {},
            )

            return (
              <IndustryPageCard
                key={index}
                title={item.title}
                textBlock={item.textBlock}
                doubleHeight={item.doubleHeight}
                backgroundStyles={item.backgroundStyles}
                image={item.image}
                cta={item.cta}
                imageStyles={imageStylesObj}
              />
            )
          })}
        </div>
      </SectionContent>
    </Section>
  )
}

export default IndustryBoxes
