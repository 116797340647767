import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import classNames from "classnames"
import Heading from "../content/heading"
import Content from "../grid/content"
import CtaGroup from "./cta-group"
import Columns from "../grid/columns"
import Link from "../content/link"
import Copy from "../content/copy"

const IndustryPageCard = ({
  title,
  textBlock,
  cta,
  image,
  backgroundStyles,
  doubleHeight,
  imageStyles,
}) => {
  const rootClasses = classNames(
    "industry-grid__card",
    doubleHeight && "industry-grid__card--tall",
  )

  const headingClasses = classNames(
    backgroundStyles ? "text--color-white" : "text--color-black",
    !doubleHeight && "industry-grid__card--half",
  )

  const textClasses = classNames(
    backgroundStyles ? "text--color-white" : "text--color-black",
    !doubleHeight && "industry-grid__card--half text--small",
  )

  const img = getImage(image.localFile)

  return (
    <div className={rootClasses} style={{ background: backgroundStyles }}>
      <Columns count={1}>
        <Content className="px-md py-md z-10" paddingRight="none">
          <Heading className={headingClasses} level={3}>
            {title}
          </Heading>
          <p className={textClasses}>{textBlock}</p>
          {cta && (
            <CtaGroup>
              <Link
                className="industry-grid__link"
                style={{ color: cta.color }}
                to={cta.url}
              >
                {cta.text}
              </Link>
            </CtaGroup>
          )}
        </Content>
        <div style={imageStyles} className="industry-grid__card__img">
          <GatsbyImage image={img} alt={image.altText} />
        </div>
      </Columns>
    </div>
  )
}

export default IndustryPageCard
